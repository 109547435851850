<template>
<!-- 游步道 -->
    <div>
        <div class="posit-left">
            <popularity></popularity>
        </div>
        <div class="posit-right">
            <taohuaisland></taohuaisland>
        </div>
    </div>
</template>

<script>
import popularity from '../charts/touristwalk/popularity.vue'
import taohuaisland from '../charts/touristwalk/taohuaIsland.vue'
export default {
    components: {
        popularity,
        taohuaisland,
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="lands darkBack">
        <div>{{lineData.name}}</div>
        <div class="landstop">
            <div>
                <div class="title">起始点：</div>
                <div class="content">{{lineData.starting}}</div>
            </div>
            <div>
                <div class="title">难度系数：</div>
                <div class="content">
                    <span v-for="count in lineData.leidiannum" style="width:0.13rem;height:0.17rem;margin-right:0.12rem" :key="count+0">
                        <img :src="lineData.leidian" alt="">
                    </span>
                    <span  v-for="count1 in lineData.difficulty - lineData.leidiannum" style="width:0.13rem;height:0.17rem;margin-right:0.12rem" :key="count1+10">
                        <img :src="lineData.unleidian" alt="">
                    </span>
                </div>
            </div>
            <div>
                <div class="title">评分：</div>
                <div class="content">
                    <span v-for="count2 in lineData.loveheartnum" style="width:0.17rem;height:0.14rem;margin-right:0.12rem" :key="count2+20">
                        <img :src="lineData.loveheart" alt="">
                    </span>
                    <span v-for="count3 in lineData.love - lineData.loveheartnum" style="width:0.17rem;height:0.14rem;margin-right:0.12rem" :key="count3+30">
                        <img :src="lineData.unloveheart" alt="">
                    </span>
                </div>
            </div>
            <div>
                <div class="title">受欢迎度：</div>
                <div class="content">有{{lineData.join_sports}}人运动过</div>
            </div>
            <div>
                <div class="title">运动占比：</div>
                <div class="content">
                    <div style="display:flex;color:white;">
                        <div style="margin-right:0.1rem;">跑步<span class="blue">{{lineData.runs}}%</span></div>
                        <div style="margin-right:0.1rem;">行走<span class="green">{{lineData.onfoot}}%</span></div>
                        <div style="margin-right:0.1rem;">骑行<span class="red">{{lineData.ride}}%</span></div>
                    </div>
                </div>
            </div>
            <div>
                <div class="title">推荐原因：</div>
                <div class="content">{{lineData.recommend}}</div>
            </div>
            <div>
                <div class="title">路面类型：</div>
                <div class="content">{{lineData.road_type}}</div>
            </div>
            <div>
                <div class="title">服务设施：</div>
                <div class="content">{{lineData.sevice_facilities}}</div>
            </div>
        </div>
        <div class="landsbot">
            <div class="tourPhoto">
                <img :src="taohua" v-for="(taohua, i) in lineData.taohuaimg" :key="i">
            </div>
        </div>
    </div>
</template>

<script>
import {getAjax, timestamp} from "../../../assets/js/websocket";
import bus from "../../../assets/js/bus";

export default {
    data () {
        return {
            lineData:{
                name:"",
                starting:"",
                leidian:require('../../../assets/images/difficulty1.png'),    // 难度 绿色的
                leidiannum: 2,            // 绿色的个数
                unleidian:require('../../../assets/images/difficulty2.png'),  // 难度  灰色的
                difficulty: 5,                                                // 难度总数
                loveheart:require('../../../assets/images/aixing1.png'),      // 受欢迎程度 红色的
                loveheartnum: 3,          // 红色的个数
                unloveheart:require('../../../assets/images/aixing2.png'),    // 受欢迎程度 灰色的
                love: 5,
                taohuaimg:[],
                sevice_facilities:"",
                road_type:"",
                recommend:"",
                join_sports:0,
                runs:0,
                ride:0,
                onfoot:0
            }
        }
    },
    computed:{
        // unleidiannum:function(){          // 难度 灰色的个数
        //     return this.data.difficulty - this.data.leidiannum
        // },
        unloveheartnum:function(){          //  受欢迎程度 灰色的个数
            return this.lineData.love - this.lineData.loveheartnum
        }
    },
    mounted () {
        this.getLineInfo();
        bus.$on('talkId',(id)=>{
            // console.log(id)
            this.getLineInfo(id);
        })
    },
    destroyed () {
        bus.$off('talkId')  
    },
    methods: {
        // 获取游步道信息
        getLineInfo(id){
            let that = this;

            let url;
            if(id == undefined) url = "/map/screen/getFootPath";
            else url = "/map/screen/getFootPath?id="+id;

            var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            getAjax({
                url: url,
                method:"GET",
                data:data,
                success:function(res){
                    // console.log(res)
                    if(res.result){
                        let data = res.data[0];
                        that.lineData.name = data.title;
                        that.lineData.starting = data.location_arr.location_str;
                        that.lineData.leidiannum = data.difficulty;
                        that.lineData.loveheartnum = data.greet;
                        that.lineData.taohuaimg = data.image_arr;
                        that.lineData.sevice_facilities = data.sevice_facilities;
                        that.lineData.road_type = data.road_type;
                        that.lineData.recommend = data.recommend;
                        that.lineData.sevice_facilities = data.sevice_facilities;
                        that.lineData.sevice_facilities = data.sevice_facilities;
                        that.lineData.join_sports = data.join_sports;
                        that.lineData.runs = data.runs;
                        that.lineData.ride = data.ride;
                        that.lineData.onfoot = data.onfoot;
                    }
                },
            })
        }
    }
}
</script>

<style scoped>
.blue{color: #1b6abc ;}
.green{color: #08ae73 ;}
.red{color: #b14c58 ;}
.blue,.red,.green{margin-left: 0.1rem;}
.title{
    width: 0.86rem;
    text-align: right;
    margin-right: 0.1rem;
}
.content{
    color:#20D7FE;
    width: 3.8rem;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.lands{
    width:5.06rem;
    /* height:5.19rem; */
    color:#FFFFFF;
    font-size: 0.2rem;
    padding: 0.2rem;
    position: relative;
}
.landstop{
    width:5.06rem;
    margin-top:0.2rem;
    color:#999999;
    font-size: 0.16rem;
}
.landstop>div{
    line-height: 0.30rem;
    display: flex;
    margin-bottom: 0.1rem;
}
.landstop>div>span{
    display: inline-block;
}
.landstop>div>span:nth-child(1){
    width:0.96rem;
    /* border:1px solid red; */
}
.landstop>div>span>img{
    width: 100%;
    height: 100%;
    vertical-align: middle;
}
.landsbot{
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top:0.2rem;
    overflow: hidden;
}
.landsbot>div{
    width: 100%;
    height: 2.8rem;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
}
.landsbot>div>img{
    width:2.14rem;
    height:1.34rem;
    margin-bottom: 0.22rem;
    margin-right: 0.1rem;
}
 /*滚动条样式*/
.tourPhoto::-webkit-scrollbar {
    width: 0.05rem;    
    height: 0rem;
}


.tourPhoto::-webkit-scrollbar-thumb {
    border-radius: 0.1rem;
    box-shadow: inset 0 0 0.05rem #bec5ce;
    background: #7EA7E2;
}
.tourPhoto::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.05rem #7EA7E2;
    border-radius: 0.1rem;
}
</style>